import React from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Progress from "../components/progress"
import {
  ajax,
  API_CALL_STATUSES,
  authAjax,
  getPathQueryParam,
  getQueryParam,
  getRedirectPathFromUrl,
  makeSelectOptions,
  redirectViaVerifyAPI,
} from "../utils"
import WithCopy from "../components/withCopy"
import CenteredContainer from "../components/CenteredContainer"
import FancyInput, { FancyCardHeader } from "../components/FancyInput"
import FancyLabel from "../components/FancyLabel"

const getErrorMessage = (err, response) => {
  switch (err && err.status ? err.status : "") {
    case 401:
      return "Password is incorrect"
    case 500:
      return "Cannot access service due to server error. Please try again later."
    default:
      return response && response.error
        ? response.error
        : "Failed to fetch user token"
  }
}

class AuthToken extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      getDetailsStatus: API_CALL_STATUSES.IDLE,
      getTokenStatus: API_CALL_STATUSES.IDLE,
      getUserInfoStatus: API_CALL_STATUSES.IDLE,
      errorMessage: "",
      token: "",
      email: "",
      password: "",
      userInfo: {},
    }
  }

  componentDidMount() {
    this.authenticateUser()
  }

  authenticateUser = () => {
    this.setState({
      getDetailsStatus: API_CALL_STATUSES.PROGRESS,
    })
    authAjax({
      path: "user/authenticate",
      type: "GET",
      success: () => {
        authAjax({
          path: "user/info",
          type: "GET",
          success: res => {
            this.setState({
              email: res.data.email,
              getDetailsStatus: API_CALL_STATUSES.SUCCESS,
            })
          },
          error: () => {
            // navigate(`/login/${getRedirectPathFromUrl()}`)
          },
        })
      },
      error: () => {
        // navigate(`/login/${getRedirectPathFromUrl()}`)
      },
    })
  }

  getAuthtoken = () => {
    const { email, password } = this.state
    this.setState({
      getTokenStatus: API_CALL_STATUSES.PROGRESS,
    })
    ajax({
      path: "user/login?type=high",
      type: "POST",
      data: {
        email,
        password,
      },
      success: res => {
        this.setState({
          token: res.data.token,
          getTokenStatus: API_CALL_STATUSES.SUCCESS,
        })
        this.getUserInfo()
      },
      error: (err, response) => {
        this.setState({
          getTokenStatus: API_CALL_STATUSES.ERROR,
          errorMessage: getErrorMessage(err, response),
        })
      },
    })
  }

  getUserInfo = () => {
    this.setState({
      getUserInfoStatus: API_CALL_STATUSES.PROGRESS,
    })
    const { coreEndpoint } = window
    authAjax({
      host: coreEndpoint,
      path: "api/user/me/get",
      headers: {
        Accept: "application/json, text/plain, */*",
      },
      success: res => {
        this.setState({
          userInfo: res,
          getUserInfoStatus: API_CALL_STATUSES.SUCCESS,
        });
        const projectOptions = makeSelectOptions(res.projects, "name", "guid");
        const selectedProject = getQueryParam('p', getPathQueryParam());
        this.onChangeProject({ target: { value: selectedProject || projectOptions?.[0]?.value }});
      },
      error: (err, response) => {
        this.setState({
          getUserInfoStatus: API_CALL_STATUSES.ERROR,
          errorMessage: getErrorMessage(err, response),
        })
      },
    })
  }

  onPasswordSubmit = e => {
    e.preventDefault()
    this.getAuthtoken()
  }

  onRedirectClick = e => {
    e.preventDefault()
    redirectViaVerifyAPI()
  }

  updatePassword = e => {
    this.setState({
      password: e.target.value,
    })
  }

  onChangeProject = e => this.setState({ selectedProject: e.target.value })

  render() {
    const {
      email,
      password,
      getDetailsStatus,
      getTokenStatus,
      errorMessage,
      token,
      getUserInfoStatus,
      userInfo: { projects, organization },
      selectedProject,
    } = this.state

    const projectOptions = makeSelectOptions(projects, "name", "guid")
    return (
      <Layout bgimage dark stickyFooter>
        <SEO title="Get Auth Token" />
        {(getDetailsStatus === API_CALL_STATUSES.PROGRESS ||
          getTokenStatus === API_CALL_STATUSES.PROGRESS) && <Progress />}
        {true && (
          <CenteredContainer>
                <FancyCardHeader title="Generate Auth token">
                {getTokenStatus === API_CALL_STATUSES.SUCCESS ? (
                  <div id="success">
                    <WithCopy value={token}></WithCopy>
                    {getUserInfoStatus === API_CALL_STATUSES.SUCCESS && (
                      <div id="containerInner">
                        <h3>Your Organization ID</h3>
                        <WithCopy value={organization?.guid} />

                        {projectOptions.length > 0 ? <>
                        <br />
                        <h3>Select Project</h3>
                        <select style={{height: "3rem"}} value={selectedProject} onChange={this.onChangeProject}>
                          {projectOptions.map(({ label, value }) => (
                            <option value={value}>{label}</option>
                          ))}
                        </select>
                        <br />
                        <br />
                        {selectedProject && (
                          <WithCopy value={selectedProject} />
                        )}
                        </> : <></>}
                        <br />
                        
                      </div>
                    )}

                    <br />
                    <p id="redirectParagraph" className="subText">
                      <span className="link text-red-600" onClick={this.onRedirectClick}>
                        Click here
                      </span>{" "}
                      to go back to the previous page
                    </p>
                    <br />
                  </div>
                ) : (
                  <form
                    noValidate
                    id="authTokenForm"
                    onSubmit={this.onPasswordSubmit}
                  >
                    <div className="inputWrapper">
                      <p className="subText">
                        Authorizing {email}
                        <br />
                        
                        <WithCopy value={email} />
                      </p>
                    </div>
                    <div className="inputWrapper">
                      <FancyLabel> Please re-enter the password for your account</FancyLabel>

                      <FancyInput
                        type="password"
                        value={password}
                        onChange={this.updatePassword}
                        required
                      />
                    </div>
                    {errorMessage && <p id="error">{errorMessage}</p>}
                    <button className="redBtn">CONTINUE</button>
                  </form>
                )}
                </FancyCardHeader>
          </CenteredContainer>
        )}
      </Layout>
    )
  }
}

export default AuthToken
